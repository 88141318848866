<script setup lang="ts">
// types
import type { User } from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// validation
import { object, ref as yupRef, string } from 'yup'

// nuxt
import { useNuxtApp } from '#app'

/**
 * props
 * ==================================================================
 */
interface Props {
  showActions?: boolean
  showCancel?: boolean
  showUsername?: boolean
  shouldValidate?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  showActions: true,
  showCancel: true,
  showUsername: true,
  shouldValidate: false
})

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits([
  'errors',
  'toggle-form',
  'toggle-valid',
  'update-form'
])

/**
 * nuxt app
 * ==================================================================
 */
const { $actor, $students, $toast, $users } = useNuxtApp()

/**
 * state
 * ==================================================================
 */
const isProcessing = ref(false)

/**
 * composables
 * ==================================================================
 */
const { doHandleError } = useErrorHandler()

/**
 * computed
 * ==================================================================
 */
const actorId = computed(() => {
  return $actor.core.actorId.value
})

const form = computed(() => {
  return {
    username: username.value,
    password: password.value,
    passwordConfirmation: passwordConfirmation.value
  }
})

const userId = computed(() => {
  return $actor.core.actor.value!.userId
})

/**
 * methods
 * ==================================================================
 */
async function update () {
  try {
    isProcessing.value = true
    if (!actorId.value) {
      throw new Error('Actor ID not found. Please refresh the page.')
    }
    const { user: _user } = await $users.update<{ user: User }>(
      `${userId.value}/change_password`,
      { ...form.value },
      { method: 'PUT' }
    )
    $actor.core.setUser(_user)
    await $students.update(
      actorId.value,
      { student: { requireSetPassword: false } }
    )
    await refetch()
    emit('toggle-form')
    $toast.success('Your password was updated successfully')
  } catch (error) {
    doHandleError(error as FetchError)
  } finally {
    isProcessing.value = false
  }
}
async function refetch () {
  try {
    await $actor.core.doRefetch()
  } catch (error) {
    doHandleError(error as FetchError, false)
  }
}
function cancel () {
  emit('toggle-form')
}

/**
 * validation
 * ==================================================================
 */
const { defineField, errors, meta, validate } = useForm({
  initialValues: {
    username: $actor.core.actor.value?.login || '',
    password: '',
    passwordConfirmation: ''
  },
  validationSchema: object({
    username: string().required().label('Username'),
    password:
      string().required().min(6)
        .matches(/\d/, 'Must contain at least 1 number')
        .label('Password'),
    passwordConfirmation:
      string().required().min(6)
        .oneOf([yupRef('password')], 'Passwords do not match')
        .label('Password confirmation')
  })
})

const { isFormValid, vuetifyConfig } = useVeeValidate(meta)

const [username, usernameAttrs] = defineField('username', vuetifyConfig)
const [password, passwordAttrs] = defineField('password', vuetifyConfig)
const [passwordConfirmation, passwordConfirmationAttrs] =
  defineField('passwordConfirmation', vuetifyConfig)

/**
 * watchers
 * ==================================================================
 */
watch(errors, () => {
  emit('errors', errors.value)
})

watch(form, () => {
  emit('update-form', form.value)
})

watch(isFormValid, () => {
  emit('toggle-valid', isFormValid.value)
})

watch(() => props.shouldValidate, async (newVal) => {
  if (newVal) {
    await validate()
  }
})
</script>

<template>
  <v-form @submit.prevent="update">
    <v-card-text>
      <v-row>
        <v-col
          v-if="props.showUsername"
          cols="12"
        >
          <r-input-text-field
            v-model="username"
            v-bind="usernameAttrs"
            :disabled="true"
            :form-validation="true"
            label="Username"
            name="Username"
            type="text"
            vid="login"
          />
        </v-col>
        <slot name="username-caption" />
        <v-col cols="12">
          <r-input-text-field
            v-model="password"
            v-bind="passwordAttrs"
            :form-validation="true"
            label="Password"
            name="Password"
            placeholder="Enter new password"
            :persist="true"
            type="password"
            vid="password"
          />
        </v-col>
        <v-col cols="12">
          <r-input-text-field
            v-model="passwordConfirmation"
            v-bind="passwordConfirmationAttrs"
            :form-validation="true"
            label="Re-enter new password"
            name="Password confirmation"
            :persist="true"
            placeholder="Re-enter new password"
            type="password"
            vid="passwordConfirmation"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions
      v-if="showActions"
      class="pa-3"
    >
      <v-btn
        v-if="props.showCancel"
        :disabled="isProcessing"
        size="small"
        type="button"
        variant="outlined"
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        data-test="btn-update"
        :disabled="!isFormValid || isProcessing"
        size="small"
        type="submit"
        variant="flat"
      >
        <lazy-r-spinner v-if="isProcessing" />
        <span v-if="isProcessing">
          Updating...
        </span>
        <span v-if="!isProcessing">
          Update
        </span>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>
